import { THEME_ENUM } from 'constants/theme.constant'

export const themeConfig = {
    "themeColor": "cyan",
    "direction": "ltr",
    "mode": "light",
    "locale": "en",
    "primaryColorLevel": 500,
    "cardBordered": false,
    "panelExpand": false,
    "controlSize": "md",
    "navMode": "light",
    "layout": {
      "type": "classic",
      "sideNavCollapse": false
    }
  }



// {
//     themeColor: 'red',
//     direction: THEME_ENUM.DIR_LTR,
//     mode: THEME_ENUM.MODE_LIGHT,
//     locale: 'en',
//     primaryColorLevel: 600,
//     cardBordered: true,
//     panelExpand: false,
//     controlSize: 'md',
//     navMode: 'themed',
//     layout: {
//         type: THEME_ENUM.LAYOUT_TYPE_MODERN,
//         sideNavCollapse: false,
//     },
// }

// {
//     "themeColor": "red",
//     "direction": "ltr",
//     "mode": "light",
//     "locale": "en",
//     "primaryColorLevel": 500,
//     "cardBordered": true,
//     "panelExpand": false,
//     "controlSize": "md",
//     "navMode": "themed",
//     "layout": {
//       "type": "modern",
//       "sideNavCollapse": false
//     }
//   }


