import api from "./api/api";

export function login(data) {
  return api.post("dentists/login", data);
}

export function adminCount() {
  return api.get("/count");
}

export function register(data) {
  return api.post("/register", data);
}

export function forgotPassword(data) {
  return api.post("dentists/forgot-password", data);
}

export function resetPassword(data, token) {
  return api.put(`dentists/password/${token}`, data);
}
